<template>
  <div class="enterprise-wrapper">
    <keep-alive>
    <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "",
      tabPosition: "left"
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  }
};

</script>

<style scoped lang="less">
.enterprise-wrapper{
  // width: 1200px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 10px;
}
</style>